export const InvoiceState = Object.freeze({
  NOT_LOADED: "notLoaded",
  LOADING: "loading",
  OK: "ok",
  EXPIRED: "expired",
  ERROR: "error",
  AUTH_REQUIRED: "authRequired",
  AUTH_PENDING: "authPending",
  AUTH_FAILED: "authFailed",
  AUTH_NOT_ALLOWED: "authNotAllowed",
  MAINTENANCE: "maintenance",
});

//These are all possible status responses from backend
export const BackendInvoiceState = Object.freeze({
  OK: "ok",
  ERROR: "error",
  EXPIRED: "expired",
  AUTH_REQUIRED: "authRequired",
  AUTH_PENDING: "authPending",
  AUTH_FAILED: "authFailed",
});

export const PdfStatus = Object.freeze({
  OK: "ok",
  ERROR: "error",
  EXPIRED: "expired",
  LOADING: "loading",
  AUTH_REQUIRED: "authRequired",
  NOT_FOUND: "notFound",
});

export const PayStatus = Object.freeze({
  OK: "ok",
  ERROR: "error",
  EXPIRED: "expired",
  LOADING: "loading",
  AUTH_REQUIRED: "authRequired",
});

export const toInvoiceState = (backendInvoiceState) => {
  switch (backendInvoiceState) {
    case BackendInvoiceState.OK:
      return InvoiceState.OK;
    case BackendInvoiceState.ERROR:
      return InvoiceState.ERROR;
    case BackendInvoiceState.EXPIRED:
      return InvoiceState.EXPIRED;
    case BackendInvoiceState.AUTH_PENDING:
      return InvoiceState.AUTH_PENDING;
    case BackendInvoiceState.AUTH_REQUIRED:
      return InvoiceState.AUTH_REQUIRED;
    case InvoiceState.AUTH_FAILED:
      return InvoiceState.AUTH_FAILED;
    default:
      return InvoiceState.ERROR;
  }
};

export const DEFAULT_LANG_SV = "sv";

export const AuthConstants = {
  stsAuthority: process.env.REACT_APP_AUTH_BASE_URL,
  clientId: "svea.inkasso.invoicing_api",
  clientRoot: process.env.REACT_APP_BASE_URL,
  clientScope:
    "openid profile common.mypages",
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  apiRoot: "",
};
