import React from "react";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AuthService from "../services/AuthService";
import "../common/i18n";
import Spinner from "./common/Spinner";

class CallbackPageClass extends React.Component {
  constructor() {
    super();
    this.authService = new AuthService();
  }

  componentDidMount() {
    const invoiceId = this.props.match.params.invoiceId;

    this.authService
      .signinRedirect()
      .then(function () {
        console.log("getting state");
        let state = JSON.parse(sessionStorage.getItem("state"));
        console.log("callback page:");
        console.log(state);
        window.location = "/" + state.invoice.invoiceId;
      })
      .catch(function (e) {
        console.error(e);
      });
  }

  render() {
    return <Spinner />;
  }
}

const CallbackPage = withTranslation()(CallbackPageClass);

const mapStateToProps = (state) => {
  //console.log('Mapping state to props:' + JSON.stringify(state.auth))
  return {};
};

export default connect(mapStateToProps, {})(CallbackPage);
