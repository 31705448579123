import { InvoiceState } from '../common/const'
import { SET_INVOICE_STATE, UPDATE_INVOICE_DATA, SET_INVOICE_ID} from './types'

export const setInvoiceState = (invoiceState, data) => {
  console.log('setinvoice state action: ' + invoiceState)
  return {
    type: SET_INVOICE_STATE,
    payload: {
      invoiceState: invoiceState,
      data: data
    }
  } 
}

export const updateInvoiceData = (invoiceState, invoiceData, invoiceTheme) => {
  console.log('update invoice data action: ' + invoiceState)
  return {
    type: UPDATE_INVOICE_DATA,
    payload: {
      invoiceState: invoiceState,
      invoiceData: invoiceData,
      invoiceTheme: invoiceTheme
    }
  } 
}

export const setInvoiceId = ( invoiceId) => {
  return {
    type: SET_INVOICE_ID,
    payload: {
      invoiceId: invoiceId,
      invoiceState: InvoiceState.NOT_LOADED
    }
  }
}