import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Spinner from "../common/Spinner";
import { setInvoiceState, updateInvoiceData } from "../../actions/invoice";
import { setLanguage, setMaintenance } from "../../actions/common";
import { setPdfStatus, setPdfData } from "../../actions/pdf";
import { theme } from "../../common/themeCreator";
import { getInvoice, getPdf } from "../../apis/backend";
import { InvoiceState, BackendInvoiceState, toInvoiceState, PdfStatus, DEFAULT_LANG_SV } from "../../common/const";
import InkassoInvoice from "./Invoice";
import AuthService from "../../services/AuthService";
import "../../common/i18n";

class InvoicePageClass extends React.Component {
  constructor() {
    super();
    this.authService = new AuthService();
  }

  handleInvoiceResponse(response) {
    console.log("handling response:" + response);
    switch (response.data.status) {
      case BackendInvoiceState.OK: //Unecessary duplicate responseJson reference for theme
        if (response.data.token) {
          this.props.setAuthToken(response.data.token);
        }
        this.props.updateInvoiceData(InvoiceState.OK, response.data, theme(response.data.templateConfigurationiguration));
        setLanguageIfNeeded(response, this.props);
        break;
      case BackendInvoiceState.EXPIRED:
        this.props.setInvoiceState(toInvoiceState(response.data.status));
        break;
      default:
        console.log("Invalid invoiceState on response:" + response.data.status);
        if (response.errorMessage) {
        }
        this.props.setInvoiceState(InvoiceState.ERROR);
    }
  }

  handlePdfResponse(response) {
    console.log("handlePdfResponse:");
    console.log(response.data.status);
    if (response.data.status === BackendInvoiceState.EXPIRED) {
      this.props.setPdfStatus(PdfStatus.EXPIRED);
    } else {
      this.props.setPdfData(response.data);
      this.props.setPdfStatus(PdfStatus.OK);
    }
  }

  handlePdfError(err) {
    this.props.setPdfStatus(PdfStatus.ERROR)
  }

  handleError(err) {
    console.log("handling error:" + err);
    if (!this.props.language) {
      if (err.response && err.response.data && err.response.data.language) {
        setLanguageForAuth(this.props, err.response.data.language);
      } else {
        setLanguageForAuth(this.props, DEFAULT_LANG_SV);
      }
    }
    if (err.response.status === 400) {
      if (err.response.data.error === "signicat_error") {
        this.props.setInvoiceState(InvoiceState.AUTH_FAILED);
      }
    } else if (err.response.status === 401) {
      if (err.response.data.subError === "unauthorized") {
        this.props.setInvoiceState(InvoiceState.AUTH_NOT_ALLOWED);
      } else if (err.response.data.subError === "failed") {
        this.props.setInvoiceState(InvoiceState.AUTH_FAILED);
      } else {
        //Signifies expired jwt token
        this.props.clearAuth();
        var authPage = err.response.data ? err.response.data.authPage : null;
        this.props.setAuthToken(null); //Clear expired token
        this.props.setInvoiceState(InvoiceState.AUTH_REQUIRED, {
          authPage: authPage,
        });
      }
    } else if (err.response.status === 403) {
      authPage = err.response.data ? err.response.data.authPage : null;
      this.props.setAuthToken(null); //Clear token so redirects work
      this.props.setInvoiceState(InvoiceState.AUTH_REQUIRED, {
        authPage: authPage,
      });
    } else if (err.response.status === 503) {
      this.props.setMaintenance();
    } else {
      console.log("Error response from backend: " + err);
      this.props.setInvoiceState(InvoiceState.ERROR);
    }
  }

  componentDidMount() {
    const invoiceId = this.props.match.params.invoiceId;
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        console.log("user is" + user);
        getInvoice(invoiceId, user.access_token)
          .then((response) => this.handleInvoiceResponse(response))
          .catch((err) => this.handleError(err));
        getPdf(invoiceId, user.access_token)
          .then((response) => this.handlePdfResponse(response))
          .catch((err) => this.handlePdfError(err));
      } else {
        console.log("User is not authenticated");
        this.props.setInvoiceState(InvoiceState.AUTH_REQUIRED);
      }
    });
  }

  render() {
    console.log("Rendering with state = " + this.props.invoiceState);
    const { t } = this.props;
    let action;
    if (this.props.location.pathname.endsWith("cancel")) {
      action = "paymentCancel";
    } else if (this.props.location.pathname.endsWith("accept")) {
      action = "paymentAccept";
    } else if (this.props.location.pathname.endsWith("error")) {
      action = "paymentError";
    }

    const invoiceId = this.props.match.params.invoiceId;

    if (this.props.maintenance === true) {
      return <Redirect to={`/maintenance`} />;
    }

    console.log("invoicepage:" + this.props.invoiceState);
    switch (this.props.invoiceState) {
      case InvoiceState.LOADING:
      case InvoiceState.NOT_LOADED:
        return <Spinner text={t("loading.label")} />;
      case InvoiceState.OK:
        if (!this.props.invoiceData || !this.props.invoiceData.templateConfiguration) {
          console.log("No invoice data or templateConfiguration");
          return t("technicalProblem.label");
        }
        return (
          <InkassoInvoice
            invoiceId={invoiceId}
            invoiceObj={this.props.invoiceData}
            theme={this.props.invoiceTheme}
            action={action}
          />
        );
      case InvoiceState.ERROR:
        return t("invoiceMissing.label");
      case InvoiceState.EXPIRED:
        return t("invoiceExpired.label");
      case InvoiceState.AUTH_REQUIRED:
      case InvoiceState.AUTH_PENDING:
        console.log("redirecting..");
        return <Redirect to={`/${invoiceId}/auth`} />;
      case InvoiceState.AUTH_FAILED:
        console.log("auth failed, redirecting..");
        return <Redirect to={`/${invoiceId}/auth`} />;
      case InvoiceState.AUTH_NOT_ALLOWED:
        return <Redirect to={"/notAuthorized"} />;
      default:
        //return t("technicalProblem.label");
        return <Redirect to={`/${invoiceId}/auth`} />;
    }
  }
}

const setLanguageIfNeeded = (response, props) => {
  //If language has not already been set, set language from invoice lang or default
  if (!props.language) {
    let lang = DEFAULT_LANG_SV;
    if (
      response.data &&
      response.data.invoice &&
      response.data.invoice.invoiceDetails &&
      response.data.invoice.invoiceDetails.language
    ) {
      lang = response.data.invoice.invoiceDetails.language;
    }
    props.i18n.changeLanguage(lang);
    props.setLanguage(lang);
  }
};

const setLanguageForAuth = (props, lang) => {
  props.i18n.changeLanguage(lang);
  props.setLanguage(lang);
};

const InvoicePage = withTranslation()(InvoicePageClass);

const mapStateToProps = (state) => {
  let invoiceState = state.invoice.invoiceState;
  //console.log('mapstatetopropse')
  //console.log(state.invoice.invoiceState)
  //Case that we are returning from auth screen after successful auth. In this case state is already AUTH_REQUIRED. Tiny bit hacky
  if (state.invoice.state === InvoiceState.AUTH_REQUIRED) {
    invoiceState = InvoiceState.LOADING;
  }

  return {
    invoiceState: invoiceState,
    invoiceData: state.invoice.invoiceData,
    invoiceTheme: state.invoice.invoiceTheme,
    authPage: state.invoice.data ? state.invoice.data.authPage : null,
    language: state.common.language,
    maintenance: state.common.maintenance,
  };
};

export default connect(mapStateToProps, {
  setInvoiceState,
  updateInvoiceData,
  setLanguage,
  setMaintenance,
  setPdfData,
  setPdfStatus,
})(InvoicePage);
