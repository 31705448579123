import { PDF_SET_DATA, PDF_SET_STATUS, AUTH_COMPLETED } from '../actions/types'
import { PdfStatus } from '../common/const'

const INITIAL_STATE = {
  status: PdfStatus.LOADING,
  pdfContent: null
}


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PDF_SET_DATA:
      return { ...state, 
        status: PdfStatus.OK,
        pdfContent: action.payload.pdfContent,
        filename: action.payload.filename
      }
    case PDF_SET_STATUS:
      //console.log('status:' + JSON.stringify(action))
      if(action.payload.status === PdfStatus.AUTH_REQUIRED) {
        return { ...state,
          status: PdfStatus.AUTH_REQUIRED,
        }
      } else {
        //console.log('returning:' + JSON.stringify(state))
        return { ...state,
          status: action.payload.status
        }
      }
    case AUTH_COMPLETED:
      return { ...state,
        status: PdfStatus.LOADING //This is to prevent bounce back to invoice on path /pdf -> /auth -> / -> pdf
      }
      
      
    default:
      return state
  }
}