import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

const getLangFromSessionStorage = () => {
  const state = sessionStorage.getItem('state')
  if(state) {
    const stateJson = JSON.parse(state)
    if(stateJson && stateJson.common) { return stateJson.common.language }
  }
  return
} 

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    lng: getLangFromSessionStorage(),
    fallbackLng: 'sv',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  })

export default i18n