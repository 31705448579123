
import { SET_LANGUAGE, SET_MAINTENANCE, CLEAR_MAINTENANCE } from './types'

export const setLanguage = (language) => {
  //console.log('Set Lang called! ' + language)
  return {
    type: SET_LANGUAGE,
    payload: {
      language: language
    }
  }
}

export const setMaintenance = () => {
  return {
    type: SET_MAINTENANCE,
  }
}

export const clearMaintenance = () => {
  return {
    type: CLEAR_MAINTENANCE,
  }
}