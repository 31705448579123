import { SET_INVOICE_ID, SET_INVOICE_STATE, UPDATE_INVOICE_DATA } from '../actions/types'
import { InvoiceState } from '../common/const'

const INITIAL_STATE = {
  invoiceState: InvoiceState.LOADING
}

export default (state = INITIAL_STATE, action) => {

  //This works as INITIAL_STATE values, since INITIAL_STATE value will not be triggered on second 
  //redux init, when values are loaded from persistent store. 
  if(state && state.invoiceState === undefined ){
    state.invoiceState = InvoiceState.LOADING
  }

  switch (action.type) {
    case SET_INVOICE_STATE:
      return { ...state, 
        invoiceState: action.payload.invoiceState,
        data: action.payload.data
      }
    case UPDATE_INVOICE_DATA:
      return { ...state,
        invoiceState: action.payload.invoiceState,
        invoiceData: action.payload.invoiceData,
        invoiceTheme: action.payload.invoiceTheme
      }
    case SET_INVOICE_ID:
      return { ...state,
        invoiceState: action.payload.invoiceState,
        invoiceId: action.payload.invoiceId
      }
    default:
      return state
  }
}