import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { Redirect, Link } from 'react-router-dom'
import './BankIdLogo.css'
import './CancelIcon.css'
import './Common.css'



export const CancelIcon = (props) => {
  return (
      <div className="inkassoCancelWrapper">
        <Link to={props.target} onClick={props.onClick}>
          <ClearIcon color="secondary" style={{margin:'20px', color:'00829a'}}/>
        </Link>
      </div>
  )
}

export const CancelIconExt = (props) => {
  return (
      <div className="inkassoCancelWrapper">
        <a href={props.target}>
          <ClearIcon color="secondary" style={{margin:'20px', color:'00829a'}}/>
        </a>
      </div>
  )
}


export const BankIdLogo = ({onLoad}) => {
  return (
    <div className="inkassoBankIdLogoWrapper">
      <div className="inkassoBankIdLogoFrame">
        <img className="inkassoBankIdLogo" src='/img/bankid_logo.png' alt='bankid logo' onLoad={onLoad ? () => {onLoad()} : {}} onError={() => this.onError()}/>
      </div>
    </div>
  )
}


const BlueButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    padding: '6px 12px',
    border: '2px solid',
    borderRadius:'150px',
    backgroundColor: '#00829a',
    borderColor: '#00829a',
    fontFamily: 'Asap-Regular',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.13,
    letterSpacing: 'normal',
    minHeight: '50px',
    textShadow: '0px 0px 1px #ffffff',
    '&:hover': {
      backgroundColor: '#00829a',
      borderColor: '#00829a',
    },
    '&:active': {
      backgroundColor: '#00829a',
      borderColor: '#00829a',
    },
    '&:focus': {
      backgroundColor: '#00829a',
      borderColor: '#00829a',
    },
  },
})(Button);

export const WhiteButton = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    color: '#00829a',
    padding: '6px 12px',
    border: '2px solid',
    borderRadius:'150px',
    backgroundColor: '#ffffff',
    borderColor: '#00829a',
    fontFamily: 'Asap-Regular',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.13,
    letterSpacing: 'normal',
    minHeight: '50px',
    textShadow: '0px 0px 1px #00829a',
    '&:hover': {
      backgroundColor: '#ffffff',
      borderColor: '#00829a',
    },
    '&:active': {
      backgroundColor: '#ffffff',
      borderColor: '#00829a',
    },
    '&:focus': {
      backgroundColor: '#ffffff',
      borderColor: '#00829a',
    },
  },
}))(Button);

export const TransparentButton = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    color: '#ffffff',
    padding: '6px 12px',
    border: '2px solid',
    borderRadius:'150px',
    backgroundColor: 'transparent',
    borderColor: '#ffffff',
    fontFamily: 'Asap-Regular',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.13,
    letterSpacing: 'normal',
    minHeight: '50px',
    textShadow: '0px 0px 1px #00829a',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: '#ffffff',
    },
    '&:active': {
      backgroundColor: 'transparent',
      borderColor: '#ffffff',
    },
    '&:focus': {
      backgroundColor: 'transparent',
      borderColor: '#ffffff',
    },
  },
}))(Button);


export const SveaLinks = () => {
  return (
    <div className="inkassoLinksWrapper">
      <div className="inkassoLink"><a href="https://svea.com">Svea.com</a></div>
      <div className="inkassoLink"><a href="https://www.svea.com/se/sv/privat/kontakt">Kontakt</a></div>
      <div className="inkassoLink"><a href="https://www.svea.com/se/sv/om-oss/svea-ekonomi/personuppgiftsbehandling">Så hanterar vi dina personuppgifter</a></div>
    </div>
  )
}

export const SveaLinksMobile = () => {
  return (
    <div>
      <div className="inkassoLinksWrapper">
        <div className="inkassoLink"><a href="https://svea.com">Svea.com</a></div>
        <div className="inkassoLink"><a href="https://www.svea.com/se/sv/privat/kontakt">Kontakt</a></div>
      </div>
      <div className="inkassoLinksWrapper">
        <div className="inkassoLink"><a href="https://www.svea.com/se/sv/om-oss/svea-ekonomi/personuppgiftsbehandling">Så hanterar vi dina personuppgifter</a></div>
      </div>
    </div>
  )
}

export const SveaFooter = () => {
  return (
    <div className="inkassoLinksWrapper">
      <div className="inkassoFooter">© 2021 Svea Inkasso AB — org.nr 556214-1423</div>
    </div>
  )
}

export const PrimaryButton = ({onClick, text}) => {
  return (
    <BlueButton fullWidth variant="contained" size="small" color="secondary" onClick={onClick ? () => onClick() : () => {}}>
      {text}
    </BlueButton>
  )
}

export const PrimaryLinkButton = (props) => {
  return (
    <Link to={props.target} className="inkassoMobileLink">
      <BlueButton fullWidth variant="contained" size="small" color="secondary" onClick={props.onClick ? () => props.onClick() : () => {}}>
        {props.text}
      </BlueButton>
    </Link>
  )
}

export const PrimaryMobileLinkButton = (props) => {
  return (
    <Link to={props.target} className="inkassoMobileLink">
      <BlueButton fullWidth variant="contained" size="small" color="secondary" onClick={props.onClick ? () => props.onClick() : () => {}}>
        {props.text}
      </BlueButton>
    </Link>
  )
}

export const SecondaryButton = ({onClick, text}) => {
  return (
    <WhiteButton fullWidth variant="contained" size="small"  onClick={onClick ? () => onClick() : () => {}}>
      {text}
      </WhiteButton> 
  )
}


export const SecondaryLinkButton = (props) => {
  return (
    <Link to={props.target ? props.target : undefined} className="inkassoOtherLink">
    <WhiteButton fullWidth variant="contained" size="small"  onClick={props.onClick ? () => props.onClick() : () => {}}>
      {props.text}
      </WhiteButton>
    </Link>
  )
}

export const SecondaryMobileLinkButton = (props) => {
  return (
    <Link to={props.target ? props.target : undefined} className="inkassoOtherLink">
    <TransparentButton fullWidth variant="contained" size="small"  >
      {props.text}
      </TransparentButton>
    </Link>
  )
}

//{t('inkassoBankIdThisDevice.label')}
