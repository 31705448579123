import { CLEAR_MAINTENANCE, SET_LANGUAGE, SET_MAINTENANCE } from "../actions/types"

const INITIAL_STATE = {
  language: '',
  i18n: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, 
        language: action.payload.language, 
      }
    case SET_MAINTENANCE:
      return { ...state,
        maintenance: true
      }
    case CLEAR_MAINTENANCE:
      return { ...state,
        maintenance: false
      }
    default:
      return state
  }
}