export const START_AUTH = "START_AUTH";
export const SHOW_MOBILE_AUTH_ERROR = "SHOW_MOBILE_AUTH_ERROR";
export const SHOW_OTHER_AUTH_ERROR = "SHOW_OTHER_AUTH_ERROR";
export const SHOW_AUTH_STATUS = "SHOW_AUTH_STATUS";
export const AUTH_COMPLETED = "AUTH_COMPLETED";
export const CLEAR_AUTH = "CLEAR_AUTH";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_AUTH_PENDING = "SET_AUTH_PENDING";
export const SET_AUTH_REDIRECT_URL = "SET_AUTH_REDIRECT_URL";
export const SET_SESSION_ID = "SET_SESSION_ID";

//Invoice
export const SET_INVOICE_STATE = "SET_INVOICE_STATE";
export const UPDATE_INVOICE_DATA = "UPDATE_INVOICE_DATA";
export const SET_INVOICE_ID = "SET_INVOICE_ID";

//Pdf
export const PDF_SET_DATA = "PDF_SET_DATA";
export const PDF_SET_STATUS = "PDF_SET_STATUS";

//Pay
export const PAY_SET_STATUS = "PAY_SET_STATUS";
export const PAY_SET_DATA = "PAY_SET_DATA";

//Common
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_MAINTENANCE = "SET_MAINTENANCE";
export const CLEAR_MAINTENANCE = "CLEAR_MAINTENANCE";
