export const loadState = () => {
  //console.log('Loading state')
  try {
    const serializedState = sessionStorage.getItem('state')
    //console.log(serializedState)
    if (serializedState === null) {
      return undefined
    }
    //console.log('State loading successful')
    return JSON.parse(serializedState)
  } catch (err) {
    console.log(err)
    return undefined
  }
}

export const saveState = (state) => {
  //console.log('Saving state')
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem('state', serializedState)
  } catch (err) {
    console.log('Error saving state to local storage:' + err)
  }
}

export const removeState = (state) => {
  //console.log('removing state')
  try {
    sessionStorage.removeItem('state')
  } catch (err) {
    console.log('Error removing state from local storage:' + err)
  }
}