import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { setInvoiceId } from '../../actions/invoice'
import { CancelIconExt, PrimaryButton, BankIdLogo, SveaLinksMobile, SveaLinks, SveaFooter } from '../common/Common'
import { SveaLogoMainPageMobile, SveaLogoMainPageDesktop } from '../common/SveaLogos'
import '../../common/i18n'
import './AuthCommon.css'
import AuthService from "../../services/AuthService";

class InkassoAuthSelectionPageClass extends React.Component {

  authService

  constructor(props) {
    super(props)
    this.state = { logoLoaded: false}
    this.authService = new AuthService()
  }
  
  onLogoLoad = () => {
    //console.log('Logo loaded!')
    this.setState({logoLoaded: true})
  }

  toMobileAuth() {
    let invoiceId = this.props.match.params.invoiceId
    return <Redirect to={`/${invoiceId}/authMobile`}/>
  }

  componentDidMount() {
    //console.log(this.props.match.params.invoiceId)
    this.props.setInvoiceId(this.props.match.params.invoiceId)
    //console.log('setting InvoiceId')
  }

  
  startAuth () {
    console.log('--Starting auth process--')
    const { t } = this.props
    this.authService.login()

    /*backend.postAuth(null, this.props.match.params.invoiceId)
    .then( async response => {
      //we get response.config
      this.props.setAuthProperties(response.data.sessionId, response.data.authUrl, true)
      this.props.setAuthRedirectUrl(response.data.authUrl)
    }).catch((err) => {
      console.log(err)
      if(err.response.status === 503) {
        this.props.setMaintenance()
      } else {
        console.log('Error response from backend: ' + err)
        this.props.setAuthPending(false)
        this.props.showMobileAuthError(t('authStartingError.label'))
      }
    })*/
  }

  
  render() {
  
    let invoiceId = this.props.match.params.invoiceId

    if(this.props.authRedirectUrl) {
      window.location=this.props.authRedirectUrl
    }

    return (
      <div className="inkassoMainFrame">
        <div className="inkassoAuthOnlyDesktop"><SveaLogoMainPageDesktop/></div>
        <div className="inkassoAuthOnlyMobile"><SveaLogoMainPageMobile/></div>
        <div className="inkassoPopupFrameMainPage">
          <div className="inkassoAuthOnlyDesktop"><CancelIconExt target="https://svea.com"/></div>
          {this.BankIdElements(invoiceId)}
        </div>

        <hr className="inkassoDivider"></hr>
        <div className="inkassoAuthOnlyDesktop"><SveaLinks/></div>
        <div className="inkassoAuthOnlyMobile"><SveaLinksMobile/></div>
        <SveaFooter/>
      </div>
      )
  }

  
  BankIdElements (invoiceId) {
    return (
      <Grid container spacing={3} className="mainGrid">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="inkassoBankIdElementsWrapper">
            <div className="inkassoAuthOnlyDesktop"><BankIdLogo onLoad={this.onLogoLoad}/></div>
          </div>
          <div className="inkassoBankIdElementsWrapper">
            {this.BankIdActionElements(invoiceId)}
          </div>
        </Grid>
      </Grid>
    )
  }

  BankIdActionElements(invoiceId) {
    const { t } = this.props

    if(this.state.logoLoaded) {
      return (
        <div className="inkassoActionWrapper">
          <div style={{width: '380px', textAlign: 'center'}}>
            <div className="inkassoAuthOnlyDesktop">
              <div className="inkassoInfoText">
                {t('inkassoOpenBankId.label')}
              </div>
            </div>
          </div>
          <div className="inkassoBankIdElement" >
            <div className="inkassoPrimaryButton">
              <PrimaryButton text={t('inkassoBankIdThisDevice.label')} onClick={() => this.startAuth()} />
            </div>
          </div>
          
          <div className="inkassoAuthOnlyDesktop">
            <div className="inkassoAvbryt"><a href="https://svea.com">{t('inkassoCancelButton.label')}</a></div>
          </div>
        </div>
      )
    }
  }
}

const InkassoAuthSelectionPage = withTranslation()(InkassoAuthSelectionPageClass)

  const mapStateToProps = (state) => {
    //console.log('Mapping state to props:' + state.auth.authPending)
    //console.log(state)
    return { 
      invoiceId: state.invoice.invoiceId
    }
  }


export default connect(mapStateToProps, {setInvoiceId})(InkassoAuthSelectionPage)