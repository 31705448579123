import axios from 'axios'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const getInvoice = (invoiceId, token) => {
  let headers = {
    Authorization : token ? 'Bearer ' + token : null
  }

  return client.get(`/client/invoices/${invoiceId}`,
    {
      headers: headers
    }
  )
}

export const payInvoice = (invoiceId, token) => {
  return client.post(`/client/invoices/${invoiceId}/pay`, 
    null,
    {
      headers: {
        Authorization : token ? 'Bearer ' + token : null
      }
    }
  )
}

export const getPdf = (invoiceId, token) => {
  return client.get(`/client/invoices/${invoiceId}/pdf`, 
  {
    headers: {
      Authorization : token ? 'Bearer ' + token : null
    }
  })
}

const backend =
{
  getInvoice: getInvoice,
  getPdf: getPdf,
}

export default backend
