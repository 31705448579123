import { Log, UserManager } from "oidc-client";

import { AuthConstants } from "../common/const";

export default class AuthService {
  constructor() {
    this.settings = {
      authority: AuthConstants.stsAuthority,
      client_id: AuthConstants.clientId,
      client_secret: AuthConstants.clientSecret,
      redirect_uri: `${AuthConstants.clientRoot}signin-callback.html`,
      silent_redirect_uri: `${AuthConstants.clientRoot}silent-renew.html`,
      // tslint:disable-next-line:object-literal-sort-keys
      post_logout_redirect_uri: `${AuthConstants.logout}`,
      response_type: "code",
      scope: AuthConstants.clientScope,
      metadata: {
        issuer: process.env.REACT_APP_AUTH_BASE_URL,
        authorization_endpoint:
          process.env.REACT_APP_AUTH_BASE_URL + "/connect/authorize",
        userinfo_endpoint:
          process.env.REACT_APP_AUTH_BASE_URL + "/connect/userinfo",
        end_session_endpoint:
          process.env.REACT_APP_AUTH_BASE_URL + "/connect/endsession",
        token_endpoint: process.env.REACT_APP_AUTH_BASE_URL + "/connect/token",
        jwks_uri:
          process.env.REACT_APP_AUTH_BASE_URL +
          "/.well-known/openid-configuration/jwks",
      },
    };
    if(process.env.REACT_APP_ENV === 'stage') {
      this.settings.acr_values = 'authMode:test'
    }
    this.userManager = new UserManager(this.settings);
    Log.logger = console;
    Log.level = Log.INFO;
  }

  getUser = () => {
    return this.userManager.getUser();
  };

  login() {
    return this.userManager.signinRedirect();
  }

  renewToken() {
    return this.userManager.signinSilent();
  }

  signinRedirect() {
    return this.userManager.signinRedirectCallback();
  }

  querySessionStatus() {
    return this.userManager.querySessionStatus();
  }

  logout() {
    return this.userManager.signoutRedirect();
  }
}
