import React from "react";
import { Redirect } from "react-router-dom";
import Spinner from "../common/Spinner";
import { connect } from "react-redux";
import { payInvoice } from "../../apis/backend";
import { paySetData, paySetStatus } from "../../actions/pay";
import { setMaintenance } from "../../actions/common";
import { PayStatus } from "../../common/const";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import "../../common/i18n";

class PayPageClass extends React.Component {
  constructor() {
    super();
    this.authService = new AuthService();
  }

  componentDidMount() {
    const invoiceId = this.props.match.params.invoiceId;

    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        console.log("user is" + user);
        payInvoice(invoiceId, user.access_token)
          .then((response) => this.handlePayResponse(response))
          .catch((err) => this.handleError(err));
      } else {
        console.log("User is not authenticated");
        this.props.paySetStatus(PayStatus.AUTH_REQUIRED);
      }
    });
  }

  handlePayResponse(response) {
    console.log("payResponse:");
    console.log(response);
    if (response.data.status === "expired") {
      this.props.paySetStatus(PayStatus.EXPIRED);
    } else {
      this.props.paySetData(response.data);
    }
  }

  handleError(err) {
    if (err.response.status === 403 || err.response.status === 401) {
      this.props.paySetStatus(PayStatus.AUTH_REQUIRED);
    } else if (err.response.status === 503) {
      this.props.setMaintenance();
    } else {
      console.log(err);
      this.props.paySetStatus(PayStatus.ERROR);
    }
  }

  render() {
    if (this.props.maintenance) {
      return <Redirect to={`/maintenance`} />;
    }

    const { t } = this.props;
    const invoiceId = this.props.match.params.invoiceId;
    switch (this.props.status) {
      case PayStatus.LOADING:
        return <Spinner />;
      case PayStatus.ERROR:
        return t("technicalProblem.label");
      case PayStatus.EXPIRED:
        return t("invoiceExpired.label");
      case PayStatus.AUTH_REQUIRED:
        return <Redirect to={`/${invoiceId}/auth`} />;
      case PayStatus.OK:
        if (this.props.data) {
          if (this.props.data.paymentProvider === "g3") {
            console.log("redirecting to " + this.props.data.url);
            window.open(this.props.data.url, "_self");
            return <Spinner />;
          } else {
            return t("paymentUnknownProvider.label");
          }
        } else {
          return t("missingData.label");
        }
      default:
        return t("unknownStatus.label");
    }
  }
}

const PayPage = withTranslation()(PayPageClass);

const mapStateToProps = (state) => {
  //console.log('Mapping state to props:' + JSON.stringify(state.auth))
  return {
    status: state.pay.status,
    data: state.pay.data,
    maintenance: state.common.maintenance,
  };
};

export default connect(mapStateToProps, {
  paySetStatus,
  paySetData,
  setMaintenance,
})(PayPage);
