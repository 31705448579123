import React from 'react';
import { removeState } from '../common/sessionStorage'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { UserManager } from 'oidc-client'

import '../common/i18n'

class LogoutPageClass extends React.Component {

  constructor() {
    super()

  }

  componentDidMount() {
    //removeState()
    //this.props.clearAuth()
  }

  render() {
    console.log(this.props)
    var um = new UserManager({ response_mode: "query" })
    um.signinRedirectCallback()
    .then( () => {
      //window.location = "index.html";
      window.location = this.props.invoiceId
      return um.getUser()
    }).then ( (user) => {
      console.log(user)
    
    }).catch(function (e) {
        console.error(e);
    });

    console.log(this.props.invoiceId)
  
    const { t } = this.props
    return (
      <div>Logged in</div>
    )
  }
}

const LogoutPage = withTranslation()(LogoutPageClass)

const mapStateToProps = (state) => {
  //console.log('Mapping state to props:' + state.auth.authPending)
  console.log(state)
  return { 
    invoiceId: state.invoice.invoiceId
  }
}

export default connect(mapStateToProps )(LogoutPage)