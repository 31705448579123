import React from 'react';
import './Common.css'

const Spinner = () => {
  return (
    <div className="inkassoSpinnerWrapper">
      <img src='/img/inkasso_spinner.gif' alt="spinner"/>
    </div>
  )
}

export default Spinner