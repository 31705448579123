import { PayStatus } from "../common/const";
import { PAY_SET_STATUS, PAY_SET_DATA } from "./types";

export const paySetStatus = (status) => {
  return {
    type: PAY_SET_STATUS,
    payload: {
      status: status,
    },
  };
};

export const paySetData = (data) => {
  return {
    type: PAY_SET_DATA,
    payload: {
      status: PayStatus.OK,
      data: data,
    },
  };
};
