import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import './index.css';
import App from './App';
import reducers from './reducers'
import { loadState, saveState } from './common/sessionStorage'

const persistedState = loadState()
const store = createStore(reducers, persistedState)


store.subscribe(() => {
  saveState({
    common: {
      language: store.getState().common.language
    },
    invoice: {
      invoiceId: store.getState().invoice.invoiceId,
    }
  })
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
, document.getElementById('root'));

if (process.env.REACT_APP_ENV !== 'development' && process.env.REACT_APP_ENV !== 'test') {
  console.log = () => {}
}
