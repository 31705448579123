import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import InvoicePage from "./components/invoice/InvoicePage";
import PayPage from './components/pay/PayPage'
import LogoutPage from "./components/LogoutPage";
import InkassoAuthSelectionPage from "./components/auth/AuthSelectionPage";
import MaintenancePage from "./components/common/MaintenancePage";
import NotAuthorizedPage from "./components/NotAuthorizedPage";
import LoginPage from "./components/LoginPage";
import CallbackPage from "./components/CallbackPage";
import "./App.css";

require("dotenv").config();

function App() {
  return (
    <Router>
      <div className="App" style={{ padding: 0 }}>
        <Suspense fallback="">
          <Switch>
            <Route path="/logout" exact component={LogoutPage} />
            <Route path="/login" exact component={LoginPage} />
            <Route path="/callback" exact component={CallbackPage}/>
            <Route path="/notAuthorized" exact component={NotAuthorizedPage} />
            <Route path="/maintenance" exact component={MaintenancePage} />
            <Route path="/:invoiceId/pay" exact component={PayPage} />
            <Route
              path="/:invoiceId/auth"
              exact
              component={InkassoAuthSelectionPage}
            />
            <Route path="/:invoiceId" exact component={InvoicePage} />
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
