import { createMuiTheme} from '@material-ui/core/styles';

export const theme = templateConfiguration => {

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: getPrimaryColor(templateConfiguration),
      },
      secondary: {
        main: getSecondaryColor(templateConfiguration),
        contrastText: getButtonTextColor(templateConfiguration),
      },
    },


    overrides: {
      MuiButton: {
        root: {
          color: "#00000",
          width: "100%",
          position: "bottom",
        },
      }
    },
  });

  function getPrimaryColor(templateConfiguration) {
    if(templateConfiguration === undefined || templateConfiguration.primaryColor === undefined) {
      return "#ffffff"
    } else {
      return templateConfiguration.primaryColor
    }
  }

  function getSecondaryColor(templateConfiguration) {
    if(templateConfiguration === undefined || templateConfiguration.secondaryColor === undefined) {
      return "#000000"
    } else {
      return templateConfiguration.secondaryColor
    }
  }

  function getButtonTextColor(templateConfiguration) {
    if(templateConfiguration === undefined || templateConfiguration.secondaryColor === undefined) {
      return "#ffffff"
    } else {
      return templateConfiguration.buttonTextColor
    }
  }



  return theme;
}