
import React from 'react';
import { connect } from 'react-redux'
import { clearMaintenance } from '../../actions/common'

class MaintenancePage extends React.Component {

  componentDidMount() {
    this.props.clearMaintenance()
  }

  render() {
    return (
      <div style={{textAlign:"center"}}>
        <h4>System maintenance ongoing - please try again later</h4>
        <h4>Systemunderhåll på gång - försök pånytt senare</h4>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {clearMaintenance}) (MaintenancePage)