import { PDF_SET_DATA, PDF_SET_STATUS} from './types'

export const setPdfData = (pdfData) => {

  return {
    type: PDF_SET_DATA,
    payload: {
      pdfContent: pdfData.pdfBytes,
      filename: pdfData.filename
    }
  }
}

export const setPdfStatus = (status) => {
  return {
    type: PDF_SET_STATUS,
    payload: {
      status: status
    }
  }
}