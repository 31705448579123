import React from 'react';
import './SveaLogos.css'

export const SveaLogoMainPageDesktop = () => {
  return (
    <div style={{marginTop: '60px', marginBottom: '42px'}}>
      <SveaLogo70pxLeft/>
    </div>
  )
}

export const SveaLogoDesktop = () => {
  return (
    <div style={{marginTop: '60px', marginBottom: '42px'}}>
      <SveaLogo70pxLeft/>
    </div>
  )
}

export const SveaLogoMainPageMobile = () => {
  return (
    <div style={{marginTop: '302px', marginBottom: '124px'}}>
      <SveaLogo70pxCentered/>
    </div>
  )
}

export const SveaLogoMobile = () => {
  return (
    <div style={{marginTop: '93px', marginBottom: '160px'}}>
      <SveaLogo50pxCentered/>
    </div>
  )
}

const SveaLogo50pxCentered = () => {
  return (
    <div className="sveaLogoCenterWrapper">
      <img className="sveaLogo50px" src='/img/Svea_logo.png' alt='bankid logo'/>
    </div>
  )
}

const SveaLogo70pxCentered = () => {
  return (
    <div className="sveaLogoCenterWrapper">
      <img className="sveaLogo70px" src='/img/Svea_logo.png' alt='bankid logo'/>
    </div>
  )
}

const SveaLogo70pxLeft = () => {
  return (
    <div className="sveaLogoLeftWrapper">
      <img className="sveaLogo70px" src='/img/Svea_logo.png' alt='bankid logo'/>
    </div>
  )
}