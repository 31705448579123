import React from "react";
import { removeState } from "../common/sessionStorage";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
//import { logout } from '../apis/backend'
import { LogoutButton } from "./invoice/InvoiceComponents";
import AuthService from "../services/AuthService";
import "./NotAuthorizedPage.css";

import "../common/i18n";

class NotAuthorizedPageClass extends React.Component {
  constructor() {
    super();
    this.authService = new AuthService();
  }

  componentDidMount() {}

  logout = () => {
    this.authService.logout();
  };

  render() {
    console.log(this.props);
    const { t } = this.props;
    return (
      <div className="notAuthorizedMain">
        <div className="notAuthorizedChild">
          <div className="notAuthorizedHeader">{t("notAuthorized.label")}</div>
        </div>
        <div className="notAuthorizedChild">
          <LogoutButton
            style={{ width: 300 }}
            target={"svea.com"}
            fullWidth
            variant="contained"
            onClick={this.logout}
          >
            {t("inkassoLogoutButton.label")}
          </LogoutButton>
        </div>
      </div>
    );
  }
}

const NotAuthorizedPage = withTranslation()(NotAuthorizedPageClass);

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(NotAuthorizedPage);
