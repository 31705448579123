import { PAY_SET_STATUS, PAY_SET_DATA, AUTH_COMPLETED } from "../actions/types";
import { PayStatus } from "../common/const";

const INITIAL_STATE = {
  status: PayStatus.LOADING,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAY_SET_STATUS:
      return { ...state, status: action.payload.status };

    case PAY_SET_DATA:
      return {
        ...state,
        status: action.payload.status,
        data: action.payload.data,
      };
    case AUTH_COMPLETED: //TODO???? needed?
      return {
        ...state,
        status: PayStatus.LOADING, //This is to prevent bounce back to invoice on path /pay -> /auth -> / -> pay
      };
    default:
      return state;
  }
};
