import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './Invoice.css';

export const SveaLogoBlueDesktop = () => {
  return (
    <div className="sveaLogoBlueWrapper onlyDesktop"><img className="sveaLogoBlue" src='/img/Svea_logo_blue.png' alt='bankid logo'/></div>
  )
}

export const SveaLogoBlueMobile = () => {
  return (
    <div className="sveaLogoBlueWrapper onlyMobile"><img className="sveaLogoBlue" src='/img/Svea_logo_blue.png' alt='bankid logo'/></div>
  )
}

export const PayButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    padding: '6px 12px',
    border: '2px solid',
    borderRadius:'150px',
    backgroundColor: '#00829a',
    borderColor: '#00829a',
    fontFamily: 'Asap-Regular',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.13,
    letterSpacing: 'normal',
    minHeight: '50px',
    textShadow: '0px 0px 1px #ffffff',
    '&:hover': {
      backgroundColor: '#00829a',
      borderColor: '#00829a',
    },
    '&:active': {
      backgroundColor: '#00829a',
      borderColor: '#00829a',
    },
    '&:focus': {
      backgroundColor: '#00829a',
      borderColor: '#00829a',
    },
  },
})(Button);

export const LogoutButton = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    color: '#00829a',
    padding: '6px 12px',
    border: '2px solid',
    borderRadius:'150px',
    backgroundColor: '#f8f8f4',
    borderColor: '#00829a',
    fontFamily: 'Asap-Regular',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.13,
    letterSpacing: 'normal',
    minHeight: '50px',
    textShadow: '0px 0px 1px #00829a',
    '&:hover': {
      backgroundColor: '#f8f8f4',
      borderColor: '#00829a',
    },
    '&:active': {
      backgroundColor: '#f8f8f4',
      borderColor: '#00829a',
    },
    '&:focus': {
      backgroundColor: '#f8f8f4',
      borderColor: '#00829a',
    },
  },
}))(Button);



export const InkassoInvoiceDetailsFrame = ({invoice}) => {
  const { t } = useTranslation()

  var total = invoice.invoiceDetails.total.toFixed(2).replace('.', ',')
  return (
    <div className="inkassoInvoiceDetailsFrame">
    <div className="inkassoInvoiceDetailsHeader">{t('inkassoPayInfo.label')}</div>
    <hr className="inkassoInvoiceDetailsDivider"/>
    <div className='inkassoInvoiceDetail'>
      <div className='inkassoInvoiceDetailsLabel'>{t('inkassoRecipient.label')} </div> 
      <div className='inkassoInvoiceDetailsValue'>{invoice.invoicerDetails.companyName}</div>
    </div>
    <div className='inkassoInvoiceDetail'>
      <div className='inkassoInvoiceDetailsLabel'>{t('inkassoPlusgiro.label')}</div>
      <div className='inkassoInvoiceDetailsValue'>{invoice.invoiceDetails.plusgirot}</div>
    </div>
    <div className='inkassoInvoiceDetail'>
      <div className='inkassoInvoiceDetailsLabel'>{t('inkassoBankgiro.label')}</div>
      <div className='inkassoInvoiceDetailsValue'>{invoice.invoiceDetails.bankgirot}</div>
    </div>
    <div className='inkassoInvoiceDetail'>
      <div className='inkassoInvoiceDetailsLabel'>{t('inkassoOCR.label')}</div>
      <div className='inkassoInvoiceDetailsValue'>{invoice.invoiceDetails.reference}</div>
    </div>
    <div className='inkassoInvoiceTotal'>{total} kr</div>
    <div className='inkassoInvoiceToPay'>{t('inkassoToPay.label')}</div>
  </div>
  )
}

export const SveaLinksBlue = () => {
  return (
    <div className="inkassoLinksWrapper">
      <div className="inkassoInvoiceLinkBlue"><a href="https://svea.com">Svea.com</a></div>
      <div className="inkassoInvoiceLinkBlue"><a href="https://www.svea.com/se/sv/privat/kontakt">Kontakt</a></div>
      <div className="inkassoInvoiceLinkBlue"><a href="https://www.svea.com/se/sv/om-oss/svea-ekonomi/personuppgiftsbehandling">Så hanterar vi dina personuppgifter</a></div>
    </div>
  )
}

export const SveaLinksBlueMobile = () => {
  return (
    <div>
      <div className="inkassoLinksWrapper">
        <div className="inkassoInvoiceLinkBlue"><a href="https://svea.com">Svea.com</a></div>
        <div className="inkassoInvoiceLinkBlue"><a href="https://www.svea.com/se/sv/privat/kontakt/">Kontakt</a></div>
      </div>
      <div className="inkassoLinksWrapper">
        <div className="inkassoInvoiceLinkBlue"><a href="https://www.svea.com/se/sv/om-oss/svea-ekonomi/personuppgiftsbehandling">Så hanterar vi dina personuppgifter</a></div>
      </div>
    </div>
  )
}


export const SveaFooterBlack = () => {
  return (
    <div className="inkassoLinksWrapper">
      <div className="inkassoFooterBlack">© 2021 Svea Inkasso AB — org.nr 556214-1423</div>
    </div>
  )
}

export const LogoutLinkButton = (props) => {
  return (
    <Link to={props.target} className="inkassoMobileLink">
      <LogoutButton fullWidth variant="contained" size="small" color="secondary" onClick={props.onClick ? () => props.onClick() : () => {}}>
        {props.text}
      </LogoutButton>
    </Link>
  )
}