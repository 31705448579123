import React from 'react';
import { removeState } from '../common/sessionStorage'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import '../common/i18n'

class LogoutPageClass extends React.Component {

  componentDidMount() {
    removeState()
    this.props.clearAuth()
  }

  render() {
    const { t } = this.props
    return (
      <div>({t('loggedOut.label')})</div>
    )
  }
}

const LogoutPage = withTranslation()(LogoutPageClass)

export default connect(null )(LogoutPage)