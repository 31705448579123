import React from "react";
import Grid from "@material-ui/core/Grid";
import { getPdf } from "../../apis/backend";
import { PdfStatus, BackendInvoiceState } from "../../common/const";
import {
  PayButton,
  LogoutButton,
  SveaLogoBlueDesktop,
  SveaLogoBlueMobile,
  InkassoInvoiceDetailsFrame,
  SveaLinksBlue,
  SveaLinksBlueMobile,
  SveaFooterBlack,
} from "./InvoiceComponents";
import PdfViewer from "./Pdf";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "../../common/i18n";
import "./Invoice.css";
import { setPdfStatus, setPdfData } from "../../actions/pdf";
import AuthService from "../../services/AuthService";

class InkassoInvoiceClass extends React.Component {
  authService;

  constructor(props) {
    super(props);
    this.state = { logoLoaded: false };
    this.authService = new AuthService();
  }

  componentDidMount() {}

  formatDueDate(str) {
    return str ? str.replace(/-/g, " ") : "";
  }

  logout = () => {
    this.authService.logout();
  };

  render() {
    console.log(process.env.REACT_APP_G3_BASE_URL);
    const { t } = this.props;
    const conf =
      this.props.invoiceObj.templateConfiguration !== undefined
        ? this.props.invoiceObj.templateConfiguration
        : {};
    //console.log(this.props.invoiceObj.invoice.invoicerDetails)
    var invoice = this.props.invoiceObj.invoice;
    let authMainPath = `/${invoice.invoiceId}/auth`;
    var creditor =
      invoice.customData && invoice.customData.creditor
        ? invoice.customData.creditor
        : invoice.invoicerDetails.companyName;

    if (conf.invoiceType === "inkasso") {
      //TODO Move to inkasso components
      return (
        <div className="inkassoInvoiceMainFrame">
          <SveaLogoBlueDesktop />
          <div className="inkassoInvoiceFrame">
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="inkassoGridContentsWrapper">
                  <div className="inkassoInvoiceInfoWrapper">
                    <div className="inkassoInvoiceHeader">
                      {t("inkassoClaimFrom.label")} {creditor}{" "}
                    </div>
                    <div className="inkassoInvoiceDueDate">
                      {t("inkassoDueDate.label")}{" "}
                      {this.formatDueDate(invoice.invoiceDetails.dueDate)}
                    </div>
                    <InkassoInvoiceDetailsFrame invoice={invoice} />
                    <div className="payButtonWrapper">
                      <PayButton
                        fullWidth
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => {
                          window.location = "/" + invoice.invoiceId + "/pay";
                        }}
                      >
                        {t("inkassoPayButton.label")}
                      </PayButton>
                    </div>
                    <div>
                      <LogoutButton
                        target={authMainPath}
                        fullWidth
                        variant="contained"
                        onClick={this.logout}
                      >
                        {t("inkassoLogoutButton.label")}
                      </LogoutButton>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="inkassoGridContentsWrapper">
                  <div className="inkassoPdfWrapper">
                    <PdfViewer
                      pdfContent={this.props.pdfContent}
                      pdfStatus={this.props.pdfStatus}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="onlyMobile">
            <SveaLogoBlueMobile />
          </div>
          <div style={{ width: "100%", flexShrink: "0" }}>
            <div className="inkassoInvoiceDivider"> </div>
          </div>
          <div className="onlyDesktop">
            <SveaLinksBlue />
          </div>
          <div className="onlyMobile">
            <SveaLinksBlueMobile />
          </div>
          <SveaFooterBlack />
        </div>
      );
    }
  }
}

const InkassoInvoice = withTranslation()(InkassoInvoiceClass);

const mapStateToProps = (state) => {
  return {
    pdfContent: state.pdf.pdfContent,
    filename: state.pdf.filename,
    pdfStatus: state.pdf.status,
  };
};

export default connect(mapStateToProps, {
  setPdfData,
  setPdfStatus,
})(InkassoInvoice);
