import React from 'react';
import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';
import { withTranslation } from 'react-i18next'
import '../../common/i18n'
import { PdfStatus } from '../../common/const';

//Use external pdf worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class PdfViewerClass extends React.Component {
  
  
  constructor(props) {
    super(props)
    this.state = {
      numPages: null,
      pageNumber: 1,
      scale: 1.0
    }
  }
 
  onDocumentLoadSuccess = ( {numPages} ) => {
    this.setState({
      numPages: numPages
    })
    // console.log(this.state)
  }

  changePage = (offset) => {
    this.setState({
      pageNumber: this.state.pageNumber + offset
    })
  }

  previousPage = () => {
    this.changePage(-1);
  }

  nextPage = () => {
    this.changePage(1);
  }

  download = () => {
    var fileDownload = require('js-file-download');
    var binaryString = window.atob(this.props.pdfContent);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
  
    fileDownload(bytes, this.props.filename)
  }

  onPageLoad = (page) => {
    const parentDiv = document.querySelector('#pdfDocument')
    let pageScale = parentDiv.clientWidth / (page.originalWidth)
    //console.log(parentDiv.clientWidth)
    if (this.state.scale !== pageScale) {
      this.setState({ scale: pageScale });
    }
  }

  render() {
    const { t } = this.props
    //console.log(this.state.scale)
    if(this.props.pdfStatus === PdfStatus.OK) {
    return (
      <div id="pdfDocument" className="inkassoPdfContainer">
          <Document
            file={`data:application/pdf;base64,${this.props.pdfContent}`}
            onLoadSuccess={this.onDocumentLoadSuccess}
            error={t('inkassoPdfError.message')}
            loading={t('inkassoPdfLoading.message')}
            >
              {/* Remove magic number, handle resize */}
              {Array.from(
                new Array(this.state.numPages),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    onLoadSuccess={this.onPageLoad}
                    scale={this.state.scale}
                    error={t('inkassoPdfError.message')}
                    loading={t('inkassoPdfLoading.message')}
                  />
                ),
              )}
          </Document>
      </div>
      
    );
    } else if(this.props.pdfStatus === PdfStatus.LOADING){
        return <div className="inkassoPdfPlaceholder">{t('inkassoPdfLoading.message')}</div>
    } else {
      return <div className="inkassoPdfPlaceholder">{t('inkassoPdfError.message')}</div>
    }
  } 
  
}

const PdfViewer = withTranslation()(PdfViewerClass)

export default PdfViewer
