import { combineReducers } from 'redux'
import commonReducer from './commonReducer'
import invoiceReducer from './invoiceReducer'
import payReducer from './payReducer'
import pdfReducer from './pdfReducer'

export default combineReducers({
  invoice: invoiceReducer,
  pdf: pdfReducer,
  pay: payReducer,
  common: commonReducer
})